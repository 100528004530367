import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ba from 'vue-ba';
import App from './App.vue';
import router from './router';
import store from './store';
import getLanguage from './utils/getLanguage';
import sensorPlugin from './plugins/sensorPlugin';
import VueSwiper from "vue-awesome-swiper";
import 'swiper/css/swiper.css';
import ViewUI from 'view-design';
import '@/utils/flexlib';
import zh from '@/lang/zh.json';
import en from '@/lang/en.json';

Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.use(ViewUI);
Vue.use(ba, 'c190e00bdb3bd57e36a734020aac3709');
Vue.use(sensorPlugin, 'https://strack.doviapps.com/sa?project=macclean-cn');
Vue.use(VueSwiper);

const messages = {
  zh,
  en,
};
const i18n = new VueI18n({
  locale: getLanguage(),
  silentFallbackWarn: true,
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
