<template>
  <div class="first-screen">
    <h1 class="app-name">{{$t(app_name)}}</h1>
    <div class="button-box">
      <download-button/>
    </div>
    <div class="swiper">
      <my-swiper/>
    </div>
  </div>
</template>

<script>
import DownloadButton from "@/components/DownloadButton";
import MySwiper from "@/components/MySwiper";
export default {
  name: "FirstScreen",
  components: {
    DownloadButton,
    MySwiper
  },
  data() {
    return {
      app_name: 'app_name',
    }
  }
}
</script>

<style scoped lang="less">
  .first-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    .app-name {
      margin-top: 1.24rem;
      font-size: 0.48rem;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.58rem;
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-top: 0.40rem;
    }
    .swiper {
      margin-top: 0.45rem;
      max-width: 1920px;
      width: 100%;
      height: 7.20rem;
    }
  }
</style>
