<template>
  <div class="nav">
    <div class="left-box">
      <div class="nav-item">
        <img class="item-img" :src="$t(logo)" alt="Logo">
      </div>
    </div>
    <div class="right-box">
      <div class="nav-item" v-for="(item) in menu_list" :key="item.name">
        <span class="item-name">
          {{ $t(item.name) }}
          <div class="drop-down">
            <div
                v-for="(product) in product_list"
                :key="product.name"
                class="drop-down-item"
                @click="jump(product.url)">
              <img class="img" :src="$t(product.img)" alt="App logo">
              <div class="text-box">
                <span class="name">{{ $t(product.name) }}</span>
                <span class="desc">{{ $t(product.desc) }}</span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {product_list} from './navList.json';
export default {
  name: "Nav",
  data() {
    return {
      logo: 'logo',
      menu_list: [
        {
          name: 'product',
        },
      ],
      product_list: product_list
    }
  },
  methods: {
    jump(url) {
      window.location.href = url;
    },
  },
}
</script>

<style scoped lang="less">
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    height: 43px;
    line-height: 43px;
    margin: 0 auto;
    padding: 0 18.75%;
    .left-box {
      text-align: left;
      .nav-item {
        .item-img {
          vertical-align: middle;
          width: 140px;
          height: 29px;
        }
      }
    }
    .right-box {
      text-align: left;
      display: flex;
      .nav-item {
        margin-right: 80px;
        .item-name {
          position: relative;
          width: 32px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 22px;
          transition: 0.2s;
          cursor: pointer;
          &:hover {
            color: #FFFFFF;
            .drop-down {
              opacity: 1;
              transform: scaleY(1);
            }
          }
          .drop-down {
            position: absolute;
            top: 40px;
            right: -40px;
            width: 300px;
            padding: 18px 0;
            background: #FFFFFF;
            box-shadow: 0px 0px 32px 0px rgba(27, 12, 2, 0.08);
            border-radius: 16px;
            transform: scaleY(0);
            transform-origin: top;
            transition: 0.3s ease-in-out;
            opacity: 0;
            text-align: left;
            .drop-down-item {
              display: flex;
              align-items: center;
              height: 80px;
              padding: 12px 12px 12px 40px;
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
              .img {
                width: 56px;
                height: 56px;
                margin-right: 16px;
              }
              .text-box {
                display: flex;
                flex-direction: column;
                .name {
                  font-size: 18px;
                  font-weight: 500;
                  color: #1A1A1A;
                  line-height: 25px;
                }
                .desc {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 20px;
                }
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
