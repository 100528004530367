<template>
  <div class="waterfall">
    <div class="left-grid">
      <div class="top-left">
        <a href="#"><div :style="backGroundStyle(0)"></div></a>
      </div>
      <div class="top-right">
        <a href="#"><div :style="backGroundStyle(1)"></div></a>
      </div>
      <div class="bottom">
        <a href="#"><div :style="backGroundStyle(2)"></div></a>
      </div>
    </div>
    <div class="right-grid">
      <div class="top">
        <a href="#"><div :style="backGroundStyle(3)"></div></a>
      </div>
      <div class="bottom-left">
        <a href="#"><div :style="backGroundStyle(4)"></div></a>
      </div>
      <div class="bottom-right">
        <a href="#"><div :style="backGroundStyle(5)"></div></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Waterfall",
  props: {
    "imgArr": {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // imageArr: this.imgArr
    }
  },
  methods: {
    backGroundStyle(i) {
      return {
        backgroundImage: 'url(' + this.imgArr[i] + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    }
  }
}
</script>

<style scoped lang="less">
  .waterfall {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 6.88rem;
    column-gap: 0.16rem; //列间距
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
        border-radius: 0.12rem;
      }
    }
    .left-grid {
      display: grid;
      grid-template-rows: 2.88rem 3.84rem;
      gap: 0.16rem;
      grid-template-areas:
          "top-left top-right"
          "bottom bottom";
      .top-left {
        grid-area: top-left;
        max-width: 100%;
      }
      .top-right {
        grid-area: top-right;
        max-width: 100%;
      }
      .bottom {
        grid-area: bottom;
        max-width: 100%;
      }
    }
    .right-grid {
      display: grid;
      grid-template-rows: 3.84rem 2.88rem;
      gap: 0.16rem;
      grid-template-areas:
          "top top"
          "bottom-left bottom-right";
      .top {
        grid-area: top;
        max-width: 100%;
      }
      .bottom-left {
        grid-area: bottom-left;
        max-width: 100%;
      }
      .bottom-right {
        grid-area: bottom-right;
        max-width: 100%;
      }
    }
  }
</style>
