<template>
  <div class="radius-dot">
    <ul class="dot-content" v-for="index in dotNums" :key="index" :class="{active:activeIndex == index}">
      <li class="dot" @click="dotClick(index)"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RadiusDot",
  props: {
    'dotNums': Number,
    'activeIndex': Number
  },
  methods: {
    dotClick(index) {
      this.$emit('dotClick',index);
    }
  }
}
</script>

<style scoped lang="less">
  .radius-dot {
    .dot-content {
      .dot {
        width: 0.16rem;
        height: 0.16rem;
        border-radius: 0.16rem;
        margin-bottom: 0.16rem;
        background-color: #fff;
        opacity: 0.32;
      }
    }
  }
  .active {
    width: 0.16rem;
    height: 0.32rem;
    margin-bottom: 0.16rem;
    background: #FFFFFF;
    border-radius: 0.10rem;
  }
</style>
