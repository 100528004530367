<template>
  <div class="forth-screen">
    <img class="app-logo" :src="$t(app_logo)" alt="app logo">
    <h1 class="forth-screen-name">{{$t(forth_name)}}</h1>
    <div class="button-box">
      <download-button/>
    </div>
  </div>
</template>

<script>
import DownloadButton from "@/components/DownloadButton";

export default {
  name: "ForthScreen",
  components: {
    DownloadButton
  },
  data() {
    return {
      app_logo: 'app_logo',
      forth_name: 'forth_name'
    }
  }
}
</script>

<style scoped lang="less">
  .forth-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    .app-logo {
      width: 1.28rem;
      height: 1.27rem;
      margin-top: 3.14rem;
      margin-right: 8.96rem;
      margin-left: 8.96rem;
    }
    .forth-screen-name {
      max-width: 800px;
      width: 41.6%;
      margin-top: 0.65rem;
      margin-bottom: 0.80rem;
      font-size: 0.48rem;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.58rem;
    }
    .button-box {
      margin-bottom: 2.62rem;
    }
  }
</style>
