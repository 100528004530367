<template>
  <div class="third-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <div class="button-box">
      <download-button/>
    </div>
    <div class="img">
      <img :src="$t(img)" alt="img">
    </div>
  </div>
</template>

<script>
import DownloadButton from "@/components/DownloadButton";
export default {
  name: "ThirdScreen",
  components: {
    DownloadButton
  },
  data() {
    return {
      title: 'title',
      img: 'img'
    }
  }
}
</script>

<style scoped lang="less">
  .third-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .title {
      width: 41.6%;
      font-size: 0.48rem;
      font-family: SFProText-Semibold, SFProText;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.58rem;
    }
    .button-box {
      margin-top: 0.40rem;
    }
    .img {
      width: 55%;
      max-width: 90%;
      max-height: 6.70rem;
      margin-top: 0.44rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
