<template>
  <div class="my-swiper" >
    <swiper class="swiper" :options="swiperOption" ref="mySwiper" @mouseenter.native="mouseEnter" @mouseleave.native="mouseLeave">
      <swiper-slide v-for="(item,index) in images"><a href="#"><img :src="item"></a></swiper-slide>
    </swiper>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </div>
</template>

<script>
import {images} from './swiperImages.json'
export default {
  name: 'MySwiper',
  title: 'Pagination',
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: -25,
        centeredSlidesBounds: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      images: images
    }
  },
  methods: {
    mouseEnter() {
      this.$refs.mySwiper.$swiper.autoplay.stop();
    },
    mouseLeave() {
        this.$refs.mySwiper.$swiper.autoplay.start();
    }
  }
}
</script>
<style lang="less" scoped>
.my-swiper {
  position: relative;
  width: 100%;
  height: 100%;
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      width: 49.5%;
      max-width: 100%;
      //height: 6.18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: 300ms;
      transform: scale(1);
      a {
        width: 100%;
        height: 6.18rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
      transform: scale(1);
      z-index: 999;
      a {
        width: 10.54rem;
        max-width: 100%;
        height: 7.20rem;
        background-image: url("../../public/image/pic_frame@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        img {
          width: 90%;
          margin-top: 0.51rem;
          height: 6.18rem;
        }
      }
    }
  }
  .swiper-button-prev {
    position: absolute;
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.28rem;
    left: 18.75%;
    top: 3.32rem;
    color: rgba(0,0,0,0)!important;
    background-image: url("../../public/image/ic_next备份@2x.png");
    background-color: rgba(255,255,255,0.3);
    background-size: 0.56rem 0.56rem;
    background-repeat: no-repeat;
    background-position: center 0;
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  }
  .swiper-button-next {
    position: absolute;
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.28rem;
    right: 18.75%;
    top: 3.32rem;
    color: rgba(0,0,0,0)!important;
    background-image: url("../../public/image/ic_next@2x.png");
    background-color: rgba(255,255,255,0.3);
    background-size: 0.56rem 0.56rem;
    background-repeat: no-repeat;
    background-position: center 0;
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  }
}
</style>
