<template>
  <button
      class="download-button button"
      :class="{ 'en-button': language === 'en' }"
      @click="downloadDirectly">
    <img :src="$t(button)" alt="Download">
    <span class="button-text">{{ $t(download_text) }}</span>
  </button>
</template>

<script>
import VersionDetail from '../../public/json/VersionDetail.json';

export default {
  name: "DownloadButton",
  data() {
    return {
      button:'button',
      download_text: 'download_text',
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    downloadDirectly() {
      const { url } = VersionDetail;
      window.open(url);
      this.$ba.trackEvent('webDownload', 'click');
      this.$sensors.track('webDownload');
    },
  },
}
</script>

<style scoped lang="less">
.button {
  position: relative;
  width: 2.22rem;
  height: 0.64rem;
  background: #5263FF;
  border-radius: 0.32rem;
  cursor: pointer;
  img {
    position: absolute;
    height: 0.38rem;
    width: 0.38rem;
    left: 0.18rem;
    top: 0.13rem;
    //vertical-align: middle;
  }
  .button-text {
    position: absolute;
    width: 1.13rem;
    height: 0.33rem;
    margin-left: 0.18rem;
    top: 0.16rem;
    right: 0.35rem;
    bottom: 0.15rem;
    font-size: 0.24rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 0.33rem;
    font-family: PingFangSC-Medium, PingFang SC;
  }
}
</style>
