import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: '',
  },
  mutations: {
    setLanguage(state, payload) {
      //TODO
      state.language = payload.language;
      /*const targetTitle = state.language.indexOf('en') !== -1 ? titleInEN : titleInCN;
      document.title = targetTitle;
      const ogTitleMeta = document.querySelector('meta[property=og\\:title]');
      ogTitleMeta.setAttribute('content', targetTitle);
      const descriptionMeta = document.querySelector('meta[name=description]');
      const ogDescriptionMeta = document.querySelector('meta[property=og\\:description]');
      const targetDescription = state.language.indexOf('en') !== -1 ? descriptionInEN : descriptionInCN;
      descriptionMeta.setAttribute('content', targetDescription);
      ogDescriptionMeta.setAttribute('content', targetDescription);
      const targetWebsiteName = state.language.indexOf('en') !== -1 ? websiteNameInEN : websiteNameInCN;
      const ogWebsiteMeta = document.querySelector('meta[property=og\\:site_name]');
      ogWebsiteMeta.setAttribute('content', targetWebsiteName);*/
    },
  },
  actions: {
  },
  modules: {
  }
})
