<template>
  <div class="footer">
    <div class="left-box">
      <img class="developer-logo" :src="$t(developer_logo)" alt="developer logo">
    </div>
    <div class="right-box">
      <span class="copyright-text">{{ $t(copyright_text) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      developer_logo: 'developer_logo',
      copyright_text: 'copyright_text'
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1920px;
  //height: 0.52rem;
  margin: 0 auto;
  padding-top: 0.15rem;
  padding-bottom: 0.12rem;
  .left-box{
    text-align: left;
    .developer-logo {
      width: 1.52rem;
      height: 0.25rem;
      width: 100%;
    }
  }
  .right-box {
    text-align: left;
    .copyright-text {
      font-size: 0.14rem;
      font-family: SFProText-Regular, SFProText;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.16rem;
    }
  }
}
</style>
