<template>
  <div class="home">
    <div class="dot-wrap" ref="dot_wrap">
      <radius-dot :dot-nums="dot_nums" :active-index="active_index" @dotClick="dotClick"/>
    </div>
    <div class="nav-wrap" ref="nav_wrap">
      <Nav></Nav>
    </div>
    <div class="first-wrap">
      <first-screen></first-screen>
    </div>
    <div class="second-wrap" ref="second_wrap">
      <second-screen/>
    </div>
    <div class="third-wrap" ref="third_wrap">
      <third-screen/>
    </div>
    <div class="forth-wrap" ref="forth_wrap">
      <forth-screen/>
    </div>
    <div class="footer-wrap">
      <Footer/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';
import SecondScreen from "@/views/SecondScreen";
import ThirdScreen from "@/views/ThirdScreen";
import ForthScreen from "@/views/ForthScreen";
import Footer from "@/views/Footer";
import RadiusDot from "@/components/RadiusDot";

export default {
  name:'Home',
  components: {
    Nav,
    FirstScreen,
    SecondScreen,
    ThirdScreen,
    ForthScreen,
    Footer,
    RadiusDot
  },
  data() {
    return {
      nav_scroll_event: null,
      dot_scroll_event: null,
      dot_nums:4,
      active_index:1,
    };
  },
  mounted() {
    /*
    * 设置Nav背景色的透明度，随滚动距离加深
    * */
    const navScrollHandler = () => {
      const scrollHeight = document.documentElement.scrollTop
        || document.body.scrollTop;
      const navWrapElement = this.$refs.nav_wrap;
      const baseHeight = 600;
      const transparent = scrollHeight > baseHeight ? 1 : scrollHeight / baseHeight;
      if(navWrapElement) {
        navWrapElement.style.backgroundColor = `rgba(9, 11, 22,${transparent})`;
      }
    };
    this.nav_scroll_event = navScrollHandler;
    document.addEventListener('scroll', this.nav_scroll_event);
    this.$ba.trackEvent('pageView');

    /*
    * 设置小圆点随滚动距离而翻页
    * */
    const dotScrollHandler = () => {
      const scrollHeight = document.documentElement.scrollTop
          || document.body.scrollTop;
      const secondHeight = this.$refs.second_wrap.offsetTop;
      const thirdHeight = this.$refs.third_wrap.offsetTop;
      const forthHeight = this.$refs.forth_wrap.offsetTop;
      let transparent = 1;
      if(scrollHeight >= secondHeight && scrollHeight < thirdHeight) {
        transparent = 2;
      }
      if(scrollHeight >= thirdHeight && scrollHeight < forthHeight) {
        transparent = 3;
      }
      if(scrollHeight >= forthHeight) {
        transparent = 4;
      }
      if (scrollHeight < secondHeight) {
        transparent = 1;
      }
      this.active_index = transparent;
    };
    this.dot_scroll_event = dotScrollHandler;
    document.addEventListener('scroll',this.dot_scroll_event);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.nav_scroll_event);
    this.nav_scroll_event = null;

    document.removeEventListener('scroll', this.dot_scroll_event);
    this.dot_scroll_event = null;
  },
  methods: {
    dotClick(index) {
      const firstHeight = 0;
      const secondHeight = this.$refs.second_wrap.offsetTop;
      const thirdHeight = this.$refs.third_wrap.offsetTop;
      const forthHeight = this.$refs.forth_wrap.offsetTop;
      switch (index) {
        case 1:
          window.scrollTo({top:firstHeight,left:0,behavior:'smooth'});
          break;
        case 2:
          window.scrollTo({top:secondHeight,left:0,behavior:'smooth'});
          break;
        case 3:
          window.scrollTo({top:thirdHeight,left:0,behavior:'smooth'});
          break;
        case 4:
          window.scrollTo({top:forthHeight,left:0,behavior:'smooth'});
          break;
      }
    }
  }
}
</script>

<style scoped lang="less">
  .home {
    background: #000000;
  }
  .dot-wrap {
    position: fixed;
    z-index: 999;
    top: 40%;
    right: 2%;
  }
  .nav-wrap {
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: rgba(9, 11, 22, 0);
  }
  .first-wrap {
    background-color: #000000;
  }
  .forth-wrap {
    background-image: url("../../public/image/bg_foot@2x.png");
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 1920px 1080px;
  }
  .footer-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
</style>
