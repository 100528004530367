import sensors from 'sa-sdk-javascript';

export default {
  install(Vue, serverURL) {
    sensors.init({
      server_url: serverURL,
      is_track_single_page: true,
      use_client_time: true,
      send_type: 'beacon',
      heatmap: {},
    });
    sensors.quick('autoTrack');
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$sensors = sensors;
  },
};
