<template>
  <div class="second-screen">
    <div class="top">
      <li class="classify-button" v-for="(item,index) in classify_texts" :key="index" :class="{active:changeBackground === index}">
        <button  @click="changeItem(index)">
          <span class="button-text">{{ item }}</span>
        </button>
      </li>
    </div>
    <div class="bottom">
      <Waterfall :img-arr="imgArr"/>
    </div>
  </div>
</template>

<script>
import Waterfall from "@/components/Waterfall";
import {classify_texts,images} from './secondScreenImages.json';

export default {
  name: "SecondScreen",
  components: {
    Waterfall
  },
  data() {
    return {
      classify_texts: classify_texts,
      changeBackground:0,
      imgArr: [],
      images: images
    }
  },
  mounted() {
    this.imgArr = this.images[0];
  },
  methods: {
    changeItem(index) {
      this.changeBackground = index;
      if(index < this.images.length) {
        this.imgArr = this.images[index];
      }
    }
  }
}
</script>

<style scoped lang="less">
  .second-screen {
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18.75%;
    margin-top: 0.57rem;
    margin-bottom: 2.18rem;
    .top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .classify-button {
        height: 0.44rem;
        text-align: center;
        padding-left: 0.16rem;
        padding-right: 0.16rem;
        margin-right: 0.16rem;
        margin-bottom: 0.16rem;
        border-radius: 0.24rem;
        border: 0.01rem solid rgba(255, 255, 255, 0.65);
        button {
          width: 100%;
          height: 100%;
          cursor: pointer;
          background: transparent;
          .button-text {
            width: auto;
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.22rem;
          }
        }
      }
    }
    .bottom {
      margin-top: 0.32rem;
      width: 100%;
    }
  }
  .active {
    background: #5263FF;
    border-color: rgba(0,0,0,1)!important;
  }
</style>
